import vianaLogo from './viana.png';

export const licenseTypeOptions = [
  { id: 0, name: 'Trial' },
  { id: 1, name: 'Monthly' },
  { id: 2, name: 'Annual' },
  { id: 3, name: '3 Years' },
  { id: 4, name: '5 Years' },
];

export const licenseTierOptions = [
  {
    id: 1,
    code: "standard",
    name: "Viana Standard License tier",
    logo: vianaLogo,
    apps: [
      {
        id: 1,
        code: "am",
        name: "Audience Measurement",
        logo: vianaLogo,
        category: "service-applet"
      }
    ]
  },
  {
    id: 2,
    code: "lite",
    name: "Viana Lite License tier",
    logo: vianaLogo,
    apps: [
      {
        id: 1,
        code: "am",
        name: "Audience Measurement",
        logo: vianaLogo,
        category: "service-applet"
      }
    ]
  }
];

export const requestsStatuses = [
  { value: 'approved', code: 'approved', label: 'Approved', classNames: 'd-inline-block px-2 py-1 fw-bold rounded border border-success bg-success bg-opacity-10 text-success', name: 'Approved', filterItemclassNames: '', id: 1 },
  { value: 'declined', code: 'declined', label: 'Declined', classNames: 'd-inline-block px-2 py-1 fw-bold rounded border border-danger bg-danger bg-opacity-10 text-danger', name: 'Declined', filterItemclassNames: '', id: 2 },
  { value: 'pending', code: 'pending', label: 'Pending', classNames: 'd-inline-block px-2 py-1 fw-bold rounded border border-primary bg-primary bg-opacity-10 text-primary', name: 'Pending / For Approval', filterItemclassNames: '', id: 3 },
  { value: 'suspended', code: 'suspended', label: 'Suspended', classNames: 'd-inline-block px-2 py-1 fw-bold rounded border border-warning bg-warning bg-opacity-10 text-warning', name: 'Suspended', filterItemclassNames: '', id: 4 },
  { value: 'decomissioned', code: 'decomissioned', label: 'Decomissioned', classNames: 'd-inline-block px-2 py-1 fw-bold rounded border border-secondary bg-secondary bg-opacity-10 text-secondary', name: 'Decomissioned', filterItemclassNames: '', id: 5 }
];

export enum FETCH_STATUS {
  INTITIAL = 'INTITIAL',
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  ERROR = 'ERROR',
};